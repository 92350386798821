import React, { useCallback, useEffect, useState } from "react";
import { OuterContainer } from "../style";
import styled from "styled-components";
import SearchField from "../../components/fields/searchField";
import TableComponent from "../../components/table";
import { errorMessage } from "../../utils/commonMethod";
import { getAlllist, getsearchdata } from "../../services/Api/collection";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { refress } from "../../services/redux/Refresstoken";
import ModalComponent from "../../components/modal";
import { Button } from "antd";
import CustomePagination from "../../components/table/pagination";

import { load } from "../../services/redux/Loader";
import { useLocation } from "react-router-dom";
import moment from "moment";


export default function Allproject() {
  const [searchText, setSearchText] = useState("");
  const [data, setData] = useState("");
  const [totalCount, setTotalCount] = useState();
  const [loading, setLoading] = useState("");
  const [openModal, setopenModal] = useState(false);
  const [record, setrecord] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const handleSearch = (searchText) => setSearchText(searchText);
  const refresstoken = useSelector((e) => e?.Refresstoken?.data);
  const handleDrawerOpenClose = () => {
    setopenModal((pre) => !pre);
  };
  const onPageChange = useCallback((page, pageSize) => {
    setCurrentPage(page);
  }, []);
  const columns = [
    {
      title: "Name",
      dataIndex: "project_name",
      key: "name",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      render:(_,data)=>(
        <p>{moment(data.start_date).format('MMM D, YYYY')}</p> 
     )

    },
    {
      title: "status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "country_code",
      dataIndex: "country_code",
      key: "country_code",
    },
    {
      title: "Details",
      key: "details",
      render: (_, dataIndex) => (
        <Userbuttons>

        
        
          <Button
            onClick={() => {
              setopenModal(true);
              setrecord(dataIndex);
              console.log(dataIndex)
            }}
          >
            View Details
          </Button>
          <a 
          //PROD/DEV/LOCAL SWITCH
          // href={`https://us02.procore.com/562949953425469/company/directory/users/${dataIndex.user_id}`} //prod
          href={`https://sandbox.procore.com/4271567/company/directory/users/${dataIndex.user_id}`} //dev
          target="_blank" rel="noreferrer">

          <Button
          >
            User Details
          </Button>
          </a>
          </Userbuttons>
        
      
      ),
    },
  ];
 
  const limit = 10;
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const dispatch = useDispatch();
  const handleSearchData = async (filterPath) => {
    setLoading(true);
    const isAccept = 5;
    const res = await getsearchdata(currentPage, limit, searchText, isAccept);
    if (res?.status === 200) {
      setTotalCount(res?.data?.TotalCount);
      setData(res?.data?.finalRequestedData);
    } else errorMessage(res);
    setLoading(false);
  };

  const getProjectLists = async () => {
    setLoading(true);
    dispatch(load(true));
    let project_id;
    if (params.has("project_id")) {
      project_id = params.get("project_id");
    } else {
      project_id = "All";
    }

    const res = await getAlllist(currentPage, limit, refresstoken, project_id);
    if (res?.status === 200) {
      setData(res?.data?.Requests);
      setTotalCount(res?.data?.TotalCount);
      dispatch(refress(res?.data?.refresh_token));
      dispatch(load(false));
    } else {
      errorMessage(res);
      dispatch(load(false));
    }
    dispatch(load(false));
    setLoading(false);
  };
  useEffect(() => {
    if (searchText) handleSearchData();
    else {
      getProjectLists();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, currentPage]);

  return (
    <Projectwrapper>
      <div className="filters">
        <div className="search-wrapper">
          <SearchField handleSearch={handleSearch} />
        </div>
      </div>
      <ModalComponent
        openModal={openModal}
        setOpenModal={handleDrawerOpenClose}
      >
        <Detailsmodal>
          <li>
            {" "}
            <span className="highlight">name : </span>{" "}
            {record?.project_name ?? "N/A"}
          </li>
          <li>
            <span className="highlight">Address : </span>
            {record?.address ?? "   N/A"}
          </li>
          <li>
            <span className="highlight"> area : </span>
            {record?.area ?? "   N/A"}
          </li>
          <li>
            <span className="highlight">Status : </span>{" "}
            {record?.status ?? "   N/A"}
          </li>
          <li>
            <span className="highlight">Start Date: </span>{" "}
            {record?.start_date ?? "N/A"}
          </li>
          <li>
            <span className="highlight">Start Time : </span>{" "}
            {record?.start_time ?? "N/A"}
          </li>
          <li>
            <span className="highlight">End Date : </span>:{" "}
            {record?.end_date ?? "N/A"}
          </li>
          <li>
            <span className="highlight">End Time : </span>{" "}
            {record?.end_time ?? "N/A"}
          </li>
          <li>
            <span className="highlight"> city : </span>
            {record?.city ?? "   N/A"}
          </li>
          <li>
            <span className="highlight"> comment : </span>
            {record?.comment ?? "   N/A"}
          </li>
          <li>
            <span className="highlight">country_code : </span>{" "}
            {record?.country_code ?? "   N/A"}
          </li>
          <li>
            <span className="highlight"> county :</span>{" "}
            {record?.county ?? "   N/A"}
          </li>
          <li>
            <span className="highlight"> state_code :</span>{" "}
            {record?.state_code ?? "   N/A"}
          </li>
        </Detailsmodal>
      </ModalComponent>

      <TableComponent data={data} columns={columns} loading={loading} />
      <CustomePagination
        current={currentPage}
        total={totalCount}
        onPageChange={onPageChange}
        defaultPageSize={limit}
      />
    </Projectwrapper>
  );
}
const Projectwrapper = styled(OuterContainer)`
  .search-wrapper {
    padding: 10px 0px;
  }
`;
const Detailsmodal = styled.div`
  li {
    font-size: 14px;
    padding: 2px;
    .highlight {
      color: black;
    }
  }
`;
const Userbuttons = styled.div`
display:flex;
justify-content: space-around;
gap:5px;

`
